@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Regular.1d2ca94d.woff);
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Bold.89b61808.woff);
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Black.6cfa65c6.woff);
  font-weight: 900;
}

@font-face {
  font-family: "Magnolia";
  src: url(/static/media/MagnoliaScript.7bd73be4.otf);
  font-weight: 400;
}


@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato/Lato-Regular.woff);
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato/Lato-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url(./fonts/Lato/Lato-Black.woff);
  font-weight: 900;
}

@font-face {
  font-family: "Magnolia";
  src: url(./fonts/Magnolia/MagnoliaScript.otf);
  font-weight: 400;
}
